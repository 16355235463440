<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <area-form></area-form>
  </div>
</template>

<script>
import customBreadcrumb from "@/view/custom/breadcrumb";
import { GET_AREA } from "@/core/services/store/areas.module";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AreaForm from './components/AreaForm.vue';
export default {
  name: "areas.edit",
  components: {
    customBreadcrumb,
    AreaForm
  },
  data(){
    return {
      customBreadcrumb: {
        title: "Sahalar",
        type: "Düzenle",
        pathName: null,
      },
    };
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle", "getArea", "getStations"]),
  },
  created() {
    this.$store.dispatch(GET_AREA, this.$route.params.id).then((res) => {
      this.$children[1].$data.id = parseInt(this.$route.params.id);
      this.$children[1].$data.name = res.stationFieldsInfo.name;
      this.$children[1].$data.installed_power = res.stationFieldsInfo.installed_power;
      this.$children[1].$data.area = res.stationFieldsInfo.area;
      this.$children[1].$data.selected_power_station = {
        power_station_id: res.stationFieldsInfo.power_station_id,
        text: res.stationFieldsInfo.power_station_name,
      };
      //Seçili Cihazları Getirme
      if (res.stationFieldsInfo.selected_devices != "") {
        let selected_devices_array = res.stationFieldsInfo.selected_devices.split(';');

        this.$children[1].$data.selected_devices_default = selected_devices_array;
        this.$children[1].$data.selected_devices = selected_devices_array;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sahalar", route: "/areas", icon: "flaticon2-layers-1" },
      { title: "Düzenle" },
    ]);
  },
};
</script>

<style scoped>
</style>
